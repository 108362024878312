import { CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Input, TextArea, Button, ConfirmationDialog } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useUpdateQuoteStatus } from 'services/quotes/updateQuoteStatus'
import { SectionedQuoteLineDTO, QuoteLineDTO } from 'models'
import { numberFormat } from 'utilities'
interface Props {
	section: SectionedQuoteLineDTO
	index: number
}

export const QuoteAcceptLine = ({ section, index }: Props) => {
	const [isAccepted, setAccepted] = useState(false)

	return (
		<div
			className="border border-gray-300 p-4 my-4 rounded-lg grid grid-cols-1 sm:grid-cols-3 gap-4"
			key={index}>
			{/* Left Column */}
			<div className="col-span-1 sm:col-span-2">
				<div className="text-gray-500 text-sm mb-3">{`Section ${section.number} - ${section.name}`}</div>
				{section.lines?.map((line, line_index) =>
					<h3 key={`line-${index}-${line_index}`} className="text-xl font-semibold mb-2">{line.itemDescription}</h3>
				)}
				{section.images?.map((image, img_index) => (
					<div
						key={`P${index}-${img_index}`}
						className="p-5 mb-5 mt-5 border rounded-lg bg-gray-100">
						<p className="text-gray-700 font-bold">
							{section.name} Photo {img_index + 1}:
						</p>
						<p className="text-gray-700 italic">{image.imageDescription}</p>
						<img
							src={image.imageUrl}
							alt={image.imageDescription}
							className="mt-2 max-w-full"
						/>
					</div>
				))}
			</div>
			{/* Right Column */}
			<div className="col-span-1">
				<table className="w-full">
					<thead>
						<tr className="border border-gray-300">
							<td className="text-center border w-40">Cost</td>
							<td className="text-center border w-40">Weekly Hire</td>
						</tr>
					</thead>
					<tbody>
						{section.lines?.map((line, line_index) => 
							<tr key={`cost-${index}-${line_index}`} className="border border-gray-300 h-9">
								<td className="text-center font-semibold w-40 px-4">
									{numberFormat.format(Number(line.price))}
								</td>
								<td className="text-center font-semibold w-40 px-4">
									{numberFormat.format(Number(line.weekly_hire))}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}
