import { useState, useCallback, useEffect } from 'react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useDropzone } from 'react-dropzone'
import { Button, Checkbox, Container, Spinner } from 'common'
import { useFileUpload } from 'services/api'
import { OptionsForDropdown } from 'utilities'
import { Dropdown } from 'primereact/dropdown'
import { useDeleteQuoteFilesById } from 'services/simple_quotes'

interface Props {
	setFieldValue: (field: string, value: any) => void
	value: any
	setData: any
	data: any
	index: number
	zones: any
	setNumFiles: any
	numFiles: number
}

export const SimpleCreateFile: React.FC<Props> = ({
	setFieldValue,
	value,
	setData,
	data,
	index,
	zones,
	setNumFiles,
	numFiles,
}: Props) => {
	const [fileUrl, setFileUrl] = useState<string>(value?.url)
	const [fileName, setFileName] = useState<string>(value?.file_name)
	const [fileLoading, setFileLoading] = useState<boolean>(false)
	const [fileSection, setFileSection] = useState<string>(value?.section)
	const [fileDescription, setFileDescription] = useState<string>(value?.description)

	const { deleteQuoteFilesById } = useDeleteQuoteFilesById()

	const { uploadFile } = useFileUpload()

	useEffect(() => {
		if (value) {
			setFileUrl(value.url)
			setFileLoading(false)
			setFileSection(value?.section)
			setFileDescription(value?.description)
			setFileName(value?.file_name)
		}
	}, [value?.url])

	const isImage = (fileUrl: string) => {
		if(!fileUrl) return false
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
		const extension = fileUrl.split('.').pop()?.toLowerCase()
		return extension ? imageExtensions.includes(extension) : false
	}

	const onDrop = useCallback(async (files: File[]) => {
		if (files?.length > 0 && files?.length < 2) {
			setFileLoading(true)
			const file = files[0]
			if (file.size <= 20 * 1024 * 1024) {
				const random = Math.floor(Math.random() * 1000)
				const splitFileName = file.name.split('.')[0]
				const splitFileExt = file.name.split('.')[1]
				const fileNameT = `${splitFileName}${random}.${splitFileExt}`

				const result = await uploadFile(file)

				if (result?.data?.url) {
					setFileUrl(result.data.url)

					const newFile = {
						index,
						url: result.data.url,
						description: '',
						section: splitFileName,
						file_name: fileNameT,
					}

					setFileName(fileNameT)

					setData((prevData: any) => {
						setFieldValue('files', [...prevData, newFile])
						return [...prevData, newFile]
					})
				}

				setFileLoading(false)
			} else {
				setFileLoading(false)
				alert('File size must be less than 20MB')
			}
		}
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	const removeUploadedFile = async () => {
		const newData = data
			.map((file: { index: number; id: number }) => {
				if (file.index !== index) {
					return file
				}
				if (file.id) {
					deleteQuoteFilesById(file.id)
					return
				}
			})
			.filter((value: any) => value !== undefined)

		if (fileUrl) {
			setFileLoading(true)
			setFileUrl('')
			setFileName('')
			setFileLoading(false)
			setFieldValue('files', newData)
			setData(newData)
			setFileDescription('')
			setFileSection('')
		}
	}

	return (
		<div className="pb-4">
			{!fileUrl ? (
				<div>
					{index + 1 === Number(numFiles) ? (
						<div className="w-full flex justify-end pr-1 -mt-5">
							<button
								type="button"
								onClick={() => {
									const newNumberFiles = Number(numFiles) - 1
									setFieldValue('max_files', String(newNumberFiles))
									setNumFiles(newNumberFiles)
								}}>
								<XCircleIcon className="text-red-400 h-5 w-5" />
							</button>
						</div>
					) : null}
					<div className="px-2" {...getRootProps()}>
						<div className="mt-1 flex justify-center px-6 py-2 border-2 border-gray-300 border-dashed rounded-md">
							<div className="space-y-1 text-center">
								<svg
									className="mx-auto h-6 w-6 text-gray-400"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 48 48"
									aria-hidden="true"></svg>
								<div className="flex text-sm text-gray-600">
									<div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
										{!isDragActive && (
											<div>
												<span>Upload a file</span>
												<input
													type="file"
													className="sr-only"
													{...getInputProps()}
												/>
											</div>
										)}
									</div>
									{!isDragActive ? (
										<p className="pl-1">or drag and drop</p>
									) : (
										<p className="pl-1">Drop file here</p>
									)}
								</div>
								{fileLoading && (
										<div className="p-10 flex justify-center items-center">
											<Spinner />
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="px-4 py-4">
					<Dropdown
						title="section_id"
						options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
						placeholder='Please select a section for this file'
						id={`zone_id-${index}`}
						value={
							fileSection ||
							data.filter((item: any) => item.index === index)[0]?.section
						}
						onChange={(e) => {
							const newArray = data.map((item: any) => {
								return item.index === index
									? {
											...item,
											section: e.target.value,
											section_label: zones.filter(
												(zone: any) => zone.zone_id === e.target.value
											)[0]?.zone_label,
									  }
									: item
							})
							setFieldValue('files', newArray)
							setFileSection(e.target.value)
						}}
						className="w-full text text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
					/>
					<input
						id={`description${index}`}
						type="text"
						className="h-8 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none text-left mt-4 px-2"
						name="description"
						placeholder='Enter a description for this file..'
						value={
							fileDescription ||
							data.filter((item: any) => item.index === index)[0]?.description
						}
						onChange={(e) => {
							const newArray = data.map((item: any) =>
								item.index === index
									? { ...item, description: e.target.value }
									: item
							)
							setFieldValue('files', newArray)
							setFileDescription(e.target.value)
						}}
					/>

					{/* <div className="-ml-3">
						<Checkbox
							options={[
								{
									label: 'Hidden from customer',
									value: 'Hidden from customer',
								},
							]}
							id="Hidden from customer"
							values={['Hidden from customer']}
							onChange={(id: string, values: string[]) => {
								console.log('Hidden from customer')
							}}
						/>
					</div> */}

					{isImage(fileUrl) ? (
						<img
							src={fileUrl}
							alt="Preview"
							className="max-w-full my-4 rounded-md border-2 border-gray-400"
						/>
					) : (
						<div className="flex items-center pt-4">
							<span className="text-blue-400 pl-2">{fileName}</span>
						</div>
					)}
				</div>
			)}
			<br />
			{fileUrl && (
				<Container className="flex justify-center items-center">
					<Button
						className='focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100'
						size="sm"
						variant="declineInverse"
						onClick={() => {
							removeUploadedFile()
						}}>
						Delete Current File
					</Button>
				</Container>
			)}
		</div>
	)
}
